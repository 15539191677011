<template>
  <div>
    <v-row>
      <v-col md="4" cols="12">
        <app-text-search-field @input="handleSearchInput($event)" />
      </v-col>
      <v-col class="text-right d-md-block" md="8">
        <v-btn
          bottom
          color="primary"
          v-if="$acl.can('stockAuditCreateEdit')"
          @click="handleNewStockAudit()"
        >
          Novo
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <CompanySelect
          :item-value="(val) => val.id"
          :filter="(company) => company.status == 1"
          ref="CompanySelect"
          v-model="searchParams.company_id"
          @input="select()"
          clearable
        />
      </v-col>
      <v-col>
        <v-chip-group
          @change="select()"
          mandatory
          v-model="searchParams.status"
        >
          <v-chip small color="primary" filter value="active"> Aberto </v-chip>
          <v-chip small color="error" filter value="closed">Fechado</v-chip>
        </v-chip-group>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="stock_audits.data"
          :items-per-page="30"
          hide-default-footer
          disable-sort
          dense
          @click:row="openStockAuditProducts($event.id)"
        >
          <template v-slot:[`item.started_at`]="{ item }"
            >{{ $format.dateTimeBr(item.started_at) }}
          </template>
          <template v-slot:[`item.finished_at`]="{ item }">
            <template v-if="item.status == 'closed'">
              {{ $format.dateTimeBr(item.finished_at) }}
            </template>

            <template v-else> -- </template>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip small :color="statusData[item.status].color">{{
              statusData[item.status].text
            }}</v-chip>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text icon color="primary" v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item
                  v-if="$acl.can('stockAuditCreateEdit')"
                  :disabled="item.status == 'closed'"
                  @click.stop="handleNewStockAudit(item.id)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="$acl.can('stockAuditCreateEdit')"
                  :disabled="item.status == 'closed'"
                  @click.stop="handleStopStockAudit(item.id)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-lock-open</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Fechar</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="$acl.can('stockAuditCreateEdit')"
                  :disabled="item.status == 'closed'"
                  @click.stop="handleDeleteStockAudit(item.id)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Apagar</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>

        <app-pagination :data="stock_audits" @click="pagination($event)" />
      </v-col>
    </v-row>
    <StoreStockAuditForm @store="select()" ref="StoreStockAuditForm" />
  </div>
</template>

<script>
import StoreStockAuditForm from "@/components/stock-audit/forms/StoreStockAuditForm";
import CompanySelect from "@/components/company/ui/CompanySelect";

export default {
  components: { StoreStockAuditForm, CompanySelect },

  data() {
    return {
      singleSelect: false,
      statusData: {
        closed: {
          text: "Fechado",
          color: "error",
        },
        active: {
          text: "Aberto",
          color: "primary",
        },
      },
      headers: [
        { text: "Código", value: "id", align: "center" },
        { text: "Nome", value: "name", align: "center", width: "27%" },
        {
          text: "Empresa",
          value: "company.name",
          align: "center",
          width: "18%",
        },
        { text: "Data Inicio", value: "started_at", align: "center" },
        { text: "Data Fim", value: "finished_at", align: "center" },
        { text: "Status", value: "status", align: "center", width: "5%" },
        { text: "", value: "action", align: "end", width: "11%" },
      ],
      loading: false,
      stock_audits: {
        data: [],
      },
      searchParams: {
        page: 1,
      },
    };
  },

  mounted() {
    this.select();
  },

  methods: {
    select() {
      this.loading = true;
      this.$http
        .index("stock/stock-audit", this.searchParams)
        .then((response) => {
          this.stock_audits = response.stock_audits;
          this.loading = false;
        });
    },

    handleSearchInput(text) {
      this.searchParams.text = text;
      this.select();
    },

    handleNewStockAudit(id) {
      this.$refs.StoreStockAuditForm.open(id);
    },

    openStockAuditProducts(stock_audit_id) {
      this.$router.push(`/produtos/estoque/inventario/${stock_audit_id}`);
    },
    handleStopStockAudit(id) {
      this.$http
        .update("stock/close-stock-audit", id)
        .then((response) => {
          this.select();
        })
        .catch((error) => {
          this.$toast.error(error.message);
        });
    },
    handleDeleteStockAudit(id) {
      this.$http
        .destroy("stock/stock-audit", id)
        .then((response) => {
          this.select();
        })
        .catch((error) => {
          this.$toast.error(error.message);
        });
    },
    pagination(page) {
      this.searchParams.page = page;
      this.select();
    },
  },
};
</script>

<style>
</style>