<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title primary-title> Cadastro de Inventário </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <app-text-field label="Nome" v-model="form.name" />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <CompanySelect
              ref="CompanySelect"
              v-model="form.company"
              :filter="(company) => company.status == 1"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions
        class="justify-end py-4"
        style="background-color: #f5f5f5"
      >
        <v-btn @click="dialog = false" text color="secondary">Cancelar</v-btn>
        <v-btn @click="handleStore()" color="primary">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CompanySelect from "@/components/company/ui/CompanySelect";

export default {
  components: {
    CompanySelect,
  },
  data() {
    return {
      dialog: false,
      title: null,
      form: {},
      baseForm: {
        id: null,
        name: null,
        company: {},
      },
    };
  },

  computed: {
    isStore() {
      return !this.form.id;
    },
  },

  methods: {
    async open(id) {
      this.reset();
      if (id) {
        this.show(id);
      }

      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    show(id) {
      this.$loading.start();
      this.$http
        .show("stock/stock-audit", id)
        .then((response) => {
          this.form = JSON.parse(JSON.stringify(response.stock_audit));
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    handleStore() {
      if (this.isStore) {
        this.store();
      } else {
        this.update();
      }
    },

    store() {
      this.$loading.start();
      this.$http
        .store("stock/stock-audit", this.form)
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    update() {
      this.$loading.start();
      this.$http
        .update("stock/stock-audit", this.form.id, this.form)
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    processSaved() {
      this.$emit("store");
      this.$loading.finish();
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>